<template>
  <div class="klavinius-teaser">
    <BoxHeader centered> klavinius </BoxHeader>
    <TeaserSwiper :id="box.id" :options="swiperOptions">
      <BaseSwiperItem v-for="image in article.attributes.images" :key="image.fileId">
        <SpunqImage :image="image" :width="640" :height="847" :mobile-width="375" :mobile-height="497" />
      </BaseSwiperItem>
    </TeaserSwiper>
  </div>
</template>

<script>
import BaseSwiperItem from '@/components/UserInterface/Swiper/BaseSwiperItem'
import BoxHeader from '@/components/UserInterface/Box/BoxHeader'
import SpunqImage from '@/components/UserInterface/Image/SpunqImage'
import TeaserSwiper from '@/components/UserInterface/Swiper/TeaserSwiper'

export default {
  components: {
    TeaserSwiper,
    BaseSwiperItem,
    BoxHeader,
    SpunqImage,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  computed: {
    article() {
      return this.box.relationships.articles[0]
    },
    swiperOptions() {
      return {
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 0,
      }
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/_vars';
@import 'assets/scss/import/mixins';

$swiper-width-desktop: 640px;
$swiper-nav-width-desktop: 820px;

.klavinius-teaser {
  margin-bottom: 26px;
  position: relative;

  @media all and (min-width: $screen-width-1023) {
    width: $max-width-article-content;
    margin: 54px auto;
  }

  .header-box {
    margin: 0 15px 26px 15px;

    @media all and (min-width: $screen-width-1023) {
      margin-bottom: 60px;
    }
  }

  .swiper-wrapper {
    @media all and (min-width: $screen-width-1023) {
      width: $max-width-article-content-item;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  & .teaser-swiper {
    max-width: 100vw;

    @media (min-width: $screen-width-1023) {
      max-width: $swiper-width-desktop;
    }

    .swiper-nav {
      width: 100vw;
      margin-left: -50vw;
      display: block;
      height: 100%;

      @media (min-width: $screen-width-1023) {
        width: $swiper-nav-width-desktop;
        margin-left: calc(#{$swiper-nav-width-desktop} / -2);
      }

      .swiper-button-prev {
        margin-left: -10px;
        background: url('~assets/icons/icon-mobile-slider-left-chevron-white.svg') no-repeat center;

        @media (min-width: $screen-width-1023) {
          @include brown-circle;

          background-image: url('~assets/icons/icon-desktop-slider-left-arrow-white.svg');
        }
      }

      .swiper-button-next {
        background: url('~assets/icons/icon-mobile-slider-right-chevron-white.svg') no-repeat center;
        margin-right: -10px;

        @media (min-width: $screen-width-1023) {
          @include brown-circle;

          background-image: url('~assets/icons/icon-desktop-slider-right-arrow-white.svg');
        }
      }
    }
  }
}
</style>
