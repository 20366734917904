<template>
  <Box>
    <div class="book-slider">
      <div class="book-slider__background">
        <img :src="backgroundImage" alt="Background Image" />
      </div>
      <div class="book-slider__content">
        <BoxHeader class="book-slider__title" centered>
          {{ box.attributes.templateOptions.collection.attributes.title }}
        </BoxHeader>
        <TeaserSwiper :id="box.id" :options="swiperOptions">
          <BaseSwiperItem v-for="book in books" :key="book.id">
            <BookTeaser :book="book" />
          </BaseSwiperItem>
        </TeaserSwiper>
        <a href="https://www.jagd.at/" class="book-slider__button button-link" target="_blank">
          Zum Österr. Jagd- und Fischerei-Verlag
        </a>
      </div>
    </div>
    <div v-if="isPayed" class="book-slider__payed-content">Bezahlte Anzeige</div>
  </Box>
</template>

<script>
import BoxHeader from '@/components/UserInterface/Box/BoxHeader'
import TeaserSwiper from '@/components/UserInterface/Swiper/TeaserSwiper'
import BaseSwiperItem from '@/components/UserInterface/Swiper/BaseSwiperItem'
import Box from '@/components/UserInterface/Box/Box'
import BookTeaser from '@/components/UserInterface/Teaser/BookTeaser'

export default {
  name: 'BookSlider',
  components: {
    BookTeaser,
    Box,
    BaseSwiperItem,
    TeaserSwiper,
    BoxHeader,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  computed: {
    backgroundImage() {
      return this.box.attributes.templateOptions.backgroundImageUrl
    },
    books() {
      return this.box.attributes.templateOptions.collection.relationships.articles
    },
    isPayed() {
      return this.books.some((book) => book.attributes.isSold)
    },
    swiperOptions() {
      return {
        slidesPerView: 4,
        spaceBetween: 102,
        breakpoints: {
          1023: {
            spaceBetween: 30,
            slidesPerView: 'auto',
          },
        },
      }
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.book-slider {
  max-width: $max-width-page;

  &__background {
    position: absolute;
    top: 30px;
    bottom: 30px;
    right: 0;
    left: 0;

    @media (min-width: $screen-width-1023) {
      top: 60px;
      bottom: 60px;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  &__title {
    position: relative;
    margin: 35px 0;

    @media (min-width: $screen-width-1023) {
      margin: 74px 0 80px;
    }

    .box-headline {
      color: $color-white;
      font-family: $source-sans-pro;
      line-height: 26px;
    }
  }

  &__button {
    padding: 9px 0 12px;
    color: $color-white;
    font-family: $source-sans-pro;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
    width: 100%;
    margin-bottom: 12px;
    position: relative;

    @media (min-width: $screen-width-1023) {
      width: auto;
      padding: 7px 20px 9px;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 60px;
    }
  }

  &__payed-content {
    color: $color-white;
    font-family: $source-sans-pro;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 13px;
    z-index: 2;

    @media (min-width: $screen-width-1023) {
      position: absolute;
      left: 20px;
      bottom: 70px;
      text-align: left;
    }
  }

  & .teaser-swiper {
    max-width: unset;
  }

  & .swiper-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto 20px;

    @media (min-width: $screen-width-1023) {
      margin: 0 auto 77px;
    }
  }

  // TODO: this selector styles should be refactored later together with swiper component
  & .teaser-swiper .swiper-nav {
    display: none;

    @media (min-width: $screen-width-1300) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(#{$max-width-teaser-swiper-desktop} + 150px);
      margin-left: calc((#{$max-width-teaser-swiper-desktop} + 150px) / -2);
    }

    @media (min-width: $screen-width-1440) {
      width: 1190px;
      margin-left: calc(1190px / -2);
    }
  }

  & .swiper-slide {
    width: 200px;

    @media (min-width: $screen-width-1023) {
      width: auto;
    }
  }

  .swiper-button-prev {
    top: 130px;
    left: -60px;
  }

  .swiper-button-next {
    top: 130px;
    right: -60px;
  }
}
</style>
