<template>
  <div class="four-teaser-row">
    <BoxHeader v-if="box.attributes.templateOptions.headline">
      {{ box.attributes.templateOptions.headline }}
    </BoxHeader>
    <div class="four-teaser-row__teasers">
      <ArticleTeaser
        v-for="article in box.relationships.articles"
        :key="article.id"
        :article="article"
        is-without-date
        class="four-teaser-row__teasers__article-teaser"
      />
    </div>
  </div>
</template>

<script>
import ArticleTeaser from '@/components/UserInterface/Teaser/ArticleTeaser'
import BoxHeader from '@/components/UserInterface/Box/BoxHeader'

export default {
  components: {
    BoxHeader,
    ArticleTeaser,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.four-teaser-row {
  padding: 0 15px;
  max-width: $max-width-content;
  align-items: center;

  @media all and (min-width: $screen-width-767) {
    margin: 0 auto 40px auto;
  }

  @media all and (min-width: $screen-width-1300 + 50px) {
    padding: 0;
  }

  &__teasers {
    justify-content: space-between;
    flex-flow: row wrap;
    display: flex;
    flex-direction: column;

    @media all and (min-width: $screen-width-767) {
      margin: 0 auto 35px auto;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    @media all and (min-width: $screen-width-1300 + 50px) {
      padding: 0;
    }

    &__article-teaser {
      margin-bottom: 15px;

      @media all and (min-width: $screen-width-767) {
        flex-basis: calc(50% - 20px / 2);
      }

      @media all and (min-width: $screen-width-1023) {
        flex-basis: calc(25% - 60px / 4);
      }
    }

    .article-teaser__title {
      padding-bottom: 5px;

      @media all and (min-width: $screen-width-767) {
        font-size: 24px;
        padding-bottom: 0;
      }
    }
  }
}
</style>
