<template>
  <div class="teaser-swiper">
    <div v-swiper:[id]="swiperOptions">
      <div class="swiper-wrapper">
        <slot />
      </div>
    </div>
    <div class="swiper-nav">
      <SwiperNavButton :id="buttonIdPrev" class="swiper-button-prev" />
      <SwiperNavButton :id="buttonIdNext" class="swiper-button-next" />
    </div>
  </div>
</template>

<script>
import SwiperNavButton from '@/components/UserInterface/Swiper/SwiperNavButton'

export default {
  name: 'TeaserSwiper',
  components: { SwiperNavButton },
  props: {
    id: {
      required: true,
      type: Number,
    },
    options: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    buttonIdNext() {
      return `${this.id}-button-next`
    },
    buttonIdPrev() {
      return `${this.id}-button-prev`
    },
    swiperOptions() {
      return {
        ...this.options,
        navigation: {
          nextEl: `#${this.buttonIdNext}`,
          prevEl: `#${this.buttonIdPrev}`,
        },
      }
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/_vars';

$max-width-teaser-swiper-desktop-arrow-container: 1190px;

.teaser-swiper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: $max-width-teaser-swiper-mobile;
  margin: 0 auto;
  position: relative;

  @media (min-width: $screen-width-1440) {
    justify-content: initial;
    max-width: $max-width-teaser-swiper-desktop;
  }

  .swiper-nav {
    position: absolute;
    left: 50%;
    display: none;
    top: 0;

    @media (min-width: $screen-width-1023) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: $max-width-teaser-swiper-desktop;
      margin-left: calc(#{$max-width-teaser-swiper-desktop} / -2);
    }

    @media (min-width: $screen-width-1440) {
      width: $max-width-teaser-swiper-desktop-arrow-container;
      margin-left: calc(#{$max-width-teaser-swiper-desktop-arrow-container} / -2);
    }

    & .swiper-button-disabled {
      display: none;
    }

    &__prev {
      left: 0;
    }

    &__next {
      right: 0;
    }
  }
}
</style>
