<template>
  <div class="five-articles-teaser">
    <BoxHeader> News </BoxHeader>
    <div class="five-articles-teaser__teasers">
      <ArticleTeaser
        v-for="(article, index) in articles"
        :key="article.id"
        :article="article"
        :is-wide="index < 2"
        :class="index < 2 ? 'five-articles-teaser__teaser-item--wide' : 'five-articles-teaser__teaser-item--tall'"
        class="five-articles-teaser__teaser-item"
      />
    </div>
  </div>
</template>

<script>
import BoxHeader from '@/components/UserInterface/Box/BoxHeader'
import ArticleTeaser from '@/components/UserInterface/Teaser/ArticleTeaser'

export default {
  components: {
    BoxHeader,
    ArticleTeaser,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.five-articles-teaser {
  &__teasers {
    @media (min-width: $screen-width-1023) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  & &__teaser-item {
    margin-bottom: 15px;

    @media (min-width: $screen-width-1023) {
      margin-bottom: 20px;
    }

    &--wide {
      height: 240px;

      @media (min-width: $screen-width-1023) {
        height: 320px;
        flex-basis: calc(50% - 10px);
      }

      h3 {
        font-size: 26px;
        line-height: 30px;

        @media (min-width: $screen-width-1023) {
          font-size: 28px;
          line-height: 32px;
        }
      }
    }

    &--tall {
      height: 160px;

      @media (min-width: $screen-width-1023) {
        height: 400px;
        flex-basis: calc(33.333% - 13.333px);
      }

      h3 {
        font-size: 22px;
        line-height: 26px;

        @media (min-width: $screen-width-1023) {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
