<template>
  <ImageCaption
    v-if="book.attributes.teaser && book.attributes.teaser.image"
    :image="book.attributes.teaser.image"
    :width="186"
    :height="280"
    :mobile-width="186"
    :mobile-height="280"
    mode="crop"
    class="book-teaser"
  >
    <h2 class="book-teaser__title">
      <ArticleLink :article="book">
        {{ book.attributes.title }}
      </ArticleLink>
    </h2>
    <p class="book-teaser__author">
      {{ book.attributes.leadText }}
    </p>
  </ImageCaption>
  <div v-else class="book-teaser">
    <img src="/img/book-teaser-placeholder.png" alt="Dieses Bild wurde nicht gefunden!" />
    <h2 class="book-teaser__title">
      <ArticleLink :article="book">
        {{ book.attributes.title }}
      </ArticleLink>
    </h2>
    <p class="book-teaser__author">
      {{ book.attributes.leadText }}
    </p>
  </div>
</template>

<script>
import ImageCaption from '@/components/UserInterface/Image/ImageCaption'
import ArticleLink from '@/components/UserInterface/Link/ArticleLink'

export default {
  name: 'BookTeaser',
  components: {
    ImageCaption,
    ArticleLink,
  },
  props: {
    book: {
      type: Object,
      required: true,
    },
  },
  created() {
    ;(this.book.attributes.teaser && this.book.attributes.teaser.image) ||
      $nuxtLogger.error('BookTeaser is required to have a related image!')
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.book-teaser {
  @include clickable-article-teaser;
  @include hovering-translate-up;

  margin-top: 3px;
  text-align: left;

  &__title {
    @include regular-source-sans-pro;

    margin: 20px 0 5px;
    color: $color-white;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .link-article {
    color: $color-white;
  }

  &__author {
    color: $color-text-medium-light;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
}
</style>
