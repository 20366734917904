<template>
  <div>
    <div
      :is="sanitizeBoxTemplate(box.attributes.templateAlias)"
      v-for="box in boxes"
      :key="parseInt(box.id)"
      :box="box"
    />
  </div>
</template>

<script>
import Hero from '@/components/Channel/ContentBox/HeroTeaser'
import FourTeaserRow from '@/components/Channel/ContentBox/FourTeaserRow'
import NewsTeaser from '@/components/Channel/ContentBox/NewsTeaser'
import FourMiniTeaserRow from '@/components/Channel/ContentBox/FourMiniTeaserRow'
import BookSlider from '@/components/Channel/ContentBox/BookSlider'
import KlaviniusTeaser from '@/components/Channel/ContentBox/KlaviniusTeaser'
import EventBox from '@/components/Channel/ContentBox/EventBox'
import FourWcProductRow from '@/components/Channel/ContentBox/FourWcProductRow'

export default {
  components: {
    Hero,
    NewsTeaser,
    FourTeaserRow,
    FourMiniTeaserRow,
    BookSlider,
    KlaviniusTeaser,
    EventBox,
    FourWcProductRow,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    boxes() {
      return this.channel.relationships.boxes.filter((box) => {
        const templateAlias = box.attributes.templateAlias
        const templateName = this.toPascalCase(this.sanitizeBoxTemplate(templateAlias))
        return typeof this.$options.components[templateName] === 'object'
      })
    },
  },
  methods: {
    toPascalCase(str) {
      str += ''
      str = str.split('-')
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].slice(0, 1).toUpperCase() + str[i].slice(1, str[i].length)
      }
      return str.join('')
    },
    sanitizeBoxTemplate(templateAlias) {
      if (templateAlias.includes('ContentBox')) {
        return templateAlias.replace(/cms\.weidwerk\._boxes\.ContentBox\./, '')
      } else if (templateAlias.includes('Box')) {
        return templateAlias.replace(/cms\.weidwerk\._boxes\.Box\./, '')
      }
    },
  },
}
</script>
