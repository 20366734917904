<template>
  <Box size="standard">
    <div class="event-box">
      <div class="event-box__heading-container">
        <BoxHeader centered>
          {{ title }}
        </BoxHeader>
      </div>
      <TeaserSwiper v-if="isUpcomingEvent" :id="box.id" :options="swiperOptions">
        <BaseSwiperItem v-for="event in box.relationships.events" :key="event.id">
          <ArticleEventTeaser :event="event" :is-upcoming-event="true" />
        </BaseSwiperItem>
      </TeaserSwiper>
      <template v-else>
        <div class="event-box__event-container">
          <ArticleEventTeaser v-for="event in events" :key="event.id" :event="event" />
        </div>
        <div :class="buttonContainerClass" class="event-box__button-container">
          <button class="button-link" @click="fetchMoreEvents">Mehr Events</button>
        </div>
      </template>
    </div>
  </Box>
</template>

<script>
import ArticleEventTeaser from '@/components/UserInterface/Teaser/EventTeaser/EventTeaser'
import { EVENT_PAST } from '@/utils/pages/events/consts/EventConsts'
import TeaserSwiper from '@/components/UserInterface/Swiper/TeaserSwiper'
import BoxHeader from '@/components/UserInterface/Box/BoxHeader'
import Box from '@/components/UserInterface/Box/Box'
import ARTICLES_QUERY from '@/gql/articles.gql'
import BaseSwiperItem from '@/components/UserInterface/Swiper/BaseSwiperItem'

export default {
  components: { BaseSwiperItem, Box, BoxHeader, TeaserSwiper, ArticleEventTeaser },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      limit: 20,
      offset: 0,
      isLastPage: false,
      events: this.box.relationships.events,
    }
  },
  computed: {
    title() {
      const defaultTitle = this.box.attributes.templateOptions.upcomingEvent === 1 ? 'Events' : 'Nachlese events'
      return this.box.attributes.templateOptions.headline || defaultTitle
    },
    buttonContainerClass() {
      return {
        'event-box__button-container--hidden': this.isLastPage,
      }
    },
    swiperOptions() {
      return {
        loop: false,
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 10,
        breakpoints: {
          1439: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          767: {
            slidesPerView: 1,
          },
        },
      }
    },
    isUpcomingEvent() {
      return this.box.attributes.templateOptions.upcomingEvent === 1
    },
  },
  methods: {
    async fetchMoreEvents() {
      this.offset = (this.page - 1) * this.limit
      const events = await this.queryEventsByDate(EVENT_PAST, this.offset)

      this.isLastPage = events.every((event) => this.events.some((paginatedEvent) => paginatedEvent.id === event.id))

      if (!this.isLastPage) {
        this.events = this.events.concat(events)
      }
    },
    async queryEventsByDate(date, offset = 0) {
      const events = await this.$apolloProvider.defaultClient.query({
        query: ARTICLES_QUERY,
        variables: {
          portalId: this.$config.portalId,
          filter_eventDate: date,
          limit: 20,
          offset,
        },
      })

      return events.data.getArticlesByPortalId.data || []
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

@include button-link;

.event-box {
  margin: 0 auto 25px;
  max-width: $max-width-teaser-swiper-mobile;

  @media (min-width: $screen-width-1440) {
    max-width: $screen-width-1440;
  }

  .swiper-container {
    @media (min-width: $screen-width-1440) {
      width: 100%;
    }
  }

  &__button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    &--hidden {
      visibility: hidden;
    }
  }

  &__event-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .event {
      margin: 0 0 20px;
    }

    @media (min-width: $screen-width-1440) {
      max-width: $max-width-teaser-swiper-desktop;
      margin: 0 auto;
    }
  }

  & .swiper-slide {
    margin-bottom: 0;
  }

  & .swiper-nav {
    height: 438px;
  }
}
</style>
