<template>
  <div class="hero-teaser" :class="{ 'hero-teaser--dark': hasDarkTitle }">
    <SpunqImage
      v-if="article.attributes.teaser && article.attributes.teaser.image"
      :image="article.attributes.teaser.image"
      :width="1300"
      :height="640"
      :mobile-width="767"
      :mobile-height="540"
      lazy
      mode="crop"
    />
    <div v-if="!hasDarkTitle" class="hero-teaser__shadow-gradient" />
    <div class="hero-teaser__content">
      <ChannelTagBig>{{ article.relationships.channels[0].attributes.name }}</ChannelTagBig>
      <h2 v-clampy="4" class="hero-teaser__title">
        <ArticleLink :article="article">
          {{ article.attributes.title }}
        </ArticleLink>
      </h2>
      <ArticleMeta :article="article" class="hero-teaser__meta" />
    </div>
  </div>
</template>

<script>
import ChannelTagBig from '@/components/UserInterface/Tag/ChannelTagBig'
import SpunqImage from '@/components/UserInterface/Image/SpunqImage'
import ArticleMeta from '@/components/Article/ArticleDefaultMeta'
import ArticleLink from '@/components/UserInterface/Link/ArticleLink'

export default {
  components: {
    ChannelTagBig,
    SpunqImage,
    ArticleMeta,
    ArticleLink,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  computed: {
    article() {
      return this.box.relationships.articles[0]
    },
    hasDarkTitle() {
      return this.article.attributes.titleColor === 'dark'
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.hero-teaser {
  @include clickable-article-teaser;
  @include hovering-overlay-image(0.04);

  max-width: $max-width-content;
  margin: 0 auto 10px auto;
  min-height: 440px;
  background-color: $color-black-light;

  &--dark {
    @include hovering-overlay-image(0.005);

    background-color: $color-white;
  }

  @media all and (min-width: $screen-width-767) {
    min-height: 540px;
    margin: 0 auto 35px auto;
  }

  img {
    min-height: 440px;
    object-fit: cover;

    @media all and (min-width: $screen-width-767) {
      min-height: 540px;
    }
  }

  &__shadow-gradient {
    position: absolute;
    top: 70%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: linear-gradient(transparent, $color-gray-dark);
  }

  &__content {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 40px 20px 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @media all and (min-width: $screen-width-767) {
      padding: 60px 20px 50px;
    }

    @media all and (min-width: $screen-width-1023) {
      padding: 60px 80px 50px;
    }
  }

  &__title {
    max-width: 90%;
    font-size: 34px;
    line-height: 46px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);

    @media all and (min-width: $screen-width-767) {
      max-width: 60%;
      font-size: 52px;
      line-height: 64px;
    }

    @at-root .hero-teaser--dark & {
      text-shadow: none;
    }

    .link-article {
      color: $color-white;

      @at-root .hero-teaser--dark & {
        color: $color-headline;
      }

      &:focus {
        color: $color-white;

        @at-root .hero-teaser--dark & {
          color: $color-headline;
        }
      }
    }
  }

  &__meta {
    z-index: 1;
  }
}
</style>
