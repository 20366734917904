<template>
  <div class="teaser-wc-product">
    <div class="teaser-wc-product__image-container">
      <img v-if="product.images.length > 0" class="teaser-wc-product__image" :src="imageSrc" :alt="image.alt" />
    </div>
    <a :href="product.url" target="_blank" class="link-article">
      <h3 v-clampy="2" class="teaser-wc-product__name">
        {{ product.name }}
      </h3>
    </a>
    <p v-clampy="3" class="teaser-wc-product__description">
      {{ product.description }}
    </p>
    <div class="teaser-wc-product__price-container">
      <div class="teaser-wc-product__price-label">Weidwerk-Preis:</div>
      <div class="teaser-wc-product__price">
        {{ product.price }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeaserWcProduct',
  props: {
    product: {
      required: true,
      type: Object,
    },
  },
  computed: {
    image() {
      return this.product.images[0]
    },
    // Original image url we got is with high resolution and we are able to request squares 100, 300, 600
    imageSrc() {
      return this.image.src.replace('.jpg', '-300x300.jpg')
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.teaser-wc-product {
  @include clickable-article-teaser;

  height: 295px;
  width: 100%;
  margin: 0 0 25px;
  position: relative;
  flex-basis: calc(50% - 15px / 2);
  flex-grow: 0;

  @media (min-width: $screen-width-767) {
    flex-basis: calc(25% - 45px / 4);
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: $screen-width-1023) {
    height: 460px;
    background: $color-white;
    padding: 15px;
  }

  &__image-container {
    height: 171px;
    width: 100%;
    background: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    overflow: hidden;

    @media (min-width: $screen-width-1023) {
      height: 235px;
      margin-bottom: 15px;
    }

    img {
      transition: transform 500ms;
    }
  }

  &:hover {
    img {
      transform: scale(1.01);
    }
  }

  &__image {
    height: 100%;
    object-fit: cover;
  }

  &__name {
    @include semibold-source-sans-pro;

    color: $color-text-very-dark;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;

    @media (min-width: $screen-width-1023) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__description {
    display: none;

    @media (min-width: $screen-width-1023) {
      display: block;
      color: $color-brown-dark;
      font-family: $source-sans-pro;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
    }
  }

  &__price-container {
    position: absolute;
    left: 0;
    bottom: 0;

    @media (min-width: $screen-width-1023) {
      left: 15px;
      bottom: 15px;
      display: flex;
      align-items: baseline;
    }
  }

  &__price-label {
    @include bold-source-sans-pro;

    color: $color-brown-dark;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 2px;

    @media (min-width: $screen-width-1023) {
      margin-right: 5px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__price {
    @include bold-source-sans-pro;

    color: $color-brown;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 23px;
  }
}
</style>
