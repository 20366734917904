<template>
  <div class="four-mini-teaser-row">
    <ArticleTeaserMini v-for="article in box.relationships.articles" :key="article.id" :article="article" />
  </div>
</template>

<script>
import ArticleTeaserMini from '@/components/UserInterface/Teaser/ArticleTeaserMini'

export default {
  components: {
    ArticleTeaserMini,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.four-mini-teaser-row {
  max-width: $max-width-content;
  margin: 0 auto 10px auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;

  @media all and (min-width: $screen-width-767) {
    margin: 0 auto 35px auto;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  @media all and (min-width: $screen-width-1300 + 50px) {
    padding: 0;
  }

  .article-teaser-mini {
    margin-bottom: 5px;

    @media all and (min-width: $screen-width-767) {
      flex-basis: calc(50% - 20px / 2);
    }

    @media all and (min-width: $screen-width-1023) {
      flex-basis: calc(25% - 60px / 4);
    }
  }
}
</style>
