<template>
  <Box v-if="box.relationships.wcproducts.length > 0" size="standard" class="four-wc-product-row__container">
    <Box size="standard" class="four-wc-product-row" gray-background>
      <BoxHeader class="four-wc-product-row__header" centered>
        {{ title }}
      </BoxHeader>
      <div class="four-wc-product-row__product-container">
        <TeaserWcProduct v-for="product in products" :key="product.id" :product="product" />
      </div>
      <a target="_blank" class="button-link four-wc-product-row__button" :href="shopUrl"> Zum Shop </a>
    </Box>
  </Box>
</template>

<script>
import Box from '@/components/UserInterface/Box/Box'
import BoxHeader from '@/components/UserInterface/Box/BoxHeader'
import TeaserWcProduct from '@/components/UserInterface/Teaser/TeaserWcProduct'
export default {
  name: 'FourWcProductRow',
  components: { TeaserWcProduct, BoxHeader, Box },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  computed: {
    shopUrl() {
      return this.$config.shopUrl
    },
    title() {
      return this.box.attributes.templateOptions.headline || 'Weidwerk-Shop'
    },
    products() {
      return this.box.relationships.wcproducts.slice(0, 4)
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.four-wc-product-row {
  @include button-link;

  padding: 25px 0 5px;

  &__container {
    & > .box__content {
      padding: 0;
      max-width: $screen-width-1440;
    }
  }

  &__header {
    @media (min-width: $screen-width-1023) {
      margin-top: 56px;
    }
  }

  &__product-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 15px;
    max-width: $screen-width-1300;
    flex-wrap: wrap;

    @media (min-width: $screen-width-767) {
      justify-content: center;
    }

    @media (min-width: $screen-width-1023) {
      margin-bottom: 60px;
    }
  }

  &__button {
    width: 100%;
    margin: 0 auto;

    &.button-link {
      display: block;
    }

    @media (min-width: $screen-width-1023) {
      margin-bottom: 60px;
      width: 177px;
    }
  }
}
</style>
