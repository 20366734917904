// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/icons/icon-mobile-slider-left-chevron-white.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/icons/icon-desktop-slider-left-arrow-white.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/icons/icon-mobile-slider-right-chevron-white.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../assets/icons/icon-desktop-slider-right-arrow-white.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".klavinius-teaser{margin-bottom:26px;position:relative}@media (min-width:1023px){.klavinius-teaser{margin:54px auto;width:805px}}.klavinius-teaser .header-box{margin:0 15px 26px}@media (min-width:1023px){.klavinius-teaser .header-box{margin-bottom:60px}.klavinius-teaser .swiper-wrapper{width:640px}}.klavinius-teaser .swiper-slide{align-items:center;display:flex;justify-content:center;text-align:center}.klavinius-teaser .teaser-swiper{max-width:100vw}@media(min-width:1023px){.klavinius-teaser .teaser-swiper{max-width:640px}}.klavinius-teaser .teaser-swiper .swiper-nav{display:block;height:100%;margin-left:-50vw;width:100vw}@media(min-width:1023px){.klavinius-teaser .teaser-swiper .swiper-nav{margin-left:-410px;width:820px}}.klavinius-teaser .teaser-swiper .swiper-nav .swiper-button-prev{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;margin-left:-10px}@media(min-width:1023px){.klavinius-teaser .teaser-swiper .swiper-nav .swiper-button-prev{background-color:#b24312;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");border-radius:100%;height:54px;width:54px}}.klavinius-teaser .teaser-swiper .swiper-nav .swiper-button-next{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat 50%;margin-right:-10px}@media(min-width:1023px){.klavinius-teaser .teaser-swiper .swiper-nav .swiper-button-next{background-color:#b24312;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");border-radius:100%;height:54px;width:54px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
