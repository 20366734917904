<template>
  <article class="article-teaser-mini">
    <div class="article-teaser-mini__content">
      <SpunqImage
        v-if="article.attributes.teaser && article.attributes.teaser.image"
        :image="article.attributes.teaser.image"
        :width="63"
        :height="63"
        :mobile-width="61"
        :mobile-height="61"
        mode="crop"
        class="article-teaser-mini__image"
      />
      <div>
        <ChannelTagMiniTeaser v-if="articleChannel">
          {{ articleChannel.attributes.name }}
        </ChannelTagMiniTeaser>
        <h3 v-clampy="2" class="article-teaser-mini__title">
          <ArticleLink :article="article">
            {{ article.attributes.pageTitle }}
          </ArticleLink>
        </h3>
      </div>
    </div>
  </article>
</template>

<script>
import SpunqImage from '@/components/UserInterface/Image/SpunqImage'
import ChannelTagMiniTeaser from '@/components/UserInterface/Tag/ChannelTagMiniTeaser'
import ArticleLink from '@/components/UserInterface/Link/ArticleLink'

export default {
  components: {
    SpunqImage,
    ChannelTagMiniTeaser,
    ArticleLink,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    articleChannel() {
      return this.article.relationships.channels[0]
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.article-teaser-mini {
  @include clickable-article-teaser;
  @include hovering-translate-right;

  @media (min-width: $screen-width-1023) {
    @include hovering-translate-up;
  }

  &__content {
    display: flex;
  }

  &__image {
    flex-shrink: 0;
    width: 61px;
    height: 61px;
    margin: 6px 16px 6px 0;

    @media (min-width: $screen-width-1023) {
      margin-right: 21px;
      width: 63px;
      height: 63px;
    }

    img {
      border-radius: 2px;
    }
  }

  &__title {
    @include semibold-source-sans-pro;

    font-size: 18px;
    line-height: 23px;
    margin: 0;

    @media (min-width: $screen-width-767) {
      font-size: 17px;
      line-height: 21px;
    }
  }

  .link-article {
    color: $color-headline;
  }
}
</style>
