<template>
  <Box :size="'large'">
    <div class="news-teaser">
      <TeaserArticlesGrid :articles="box.relationships.articles" class="news-teaser__articles-teaser" />
      <TeaserNewsticker class="news-teaser__newsticker" />
    </div>
  </Box>
</template>

<script>
import Box from '@/components/UserInterface/Box/Box'
import TeaserArticlesGrid from '@/components/UserInterface/Teaser/TeaserGridArticles'
import TeaserNewsticker from '@/components/UserInterface/Teaser/TeaserNewsticker'

export default {
  components: {
    Box,
    TeaserArticlesGrid,
    TeaserNewsticker,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.news-teaser {
  @media (min-width: $screen-width-767) {
    display: flex;
  }

  @media (min-width: $screen-width-1023) {
    position: relative;

    & .newsticker__button-more {
      position: absolute;
      bottom: -80px;
      left: 0;
      right: 0;
      margin: auto;
      width: 178px;
    }
  }

  &__articles-teaser {
    margin-bottom: 24px;

    @media (min-width: $screen-width-767) {
      flex: 3;
      margin: 0 20px 0 0;
    }
  }

  &__newsticker {
    @media (min-width: $screen-width-767) {
      flex: 2;
    }

    @media (min-width: $screen-width-1023) {
      flex: 1;
    }
  }
}
</style>
