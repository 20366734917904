<template>
  <button :id="id" class="swiper-nav-button" :class="className" />
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: [String, Number],
    },
    next: {
      required: false,
      type: Boolean,
    },
    prev: {
      required: false,
      type: Boolean,
    },
  },
  computed: {
    className() {
      if (this.next) {
        return 'swiper-button-next'
      } else if (this.prev) {
        return 'swiper-button-prev'
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.swiper-nav-button {
  @include brown-circle;

  background-repeat: no-repeat;
  background-size: 20px 14px;
}

.swiper-button-next {
  background-image: url('~assets/icons/icon-desktop-slider-right-arrow-white.svg');
}

.swiper-button-prev {
  background-image: url('~assets/icons/icon-desktop-slider-left-arrow-white.svg');
}
</style>
