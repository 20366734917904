import { makeUrl } from '@/utils/image-url'

export function generateTitle(title, $config) {
  return title + ' | ' + $config.pageTitle
}

export function generateMetaTags({ title, description, url, image, imageWidth, imageHeight, $config }) {
  return [
    // hid is used as unique identifier. Do not use `vmid` for it as it will not work
    {
      hid: 'description',
      name: 'description',
      content: description,
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: generateTitle(title, $config),
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: title,
    },
    {
      hid: 'og:description',
      name: 'og:description',
      property: 'og:description',
      content: description,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: $config.baseUrl + url,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content:
        image && image.attributes
          ? makeUrl(image.attributes.fileId, image.attributes.fileName, imageWidth, imageHeight, 'c', $config)
          : image,
    },
    {
      hid: 'og:image:width',
      property: 'og:image:width',
      content: imageWidth,
    },
    {
      hid: 'og:image:height',
      property: 'og:image:height',
      content: imageHeight,
    },
  ]
}
