<template>
  <div class="page-channel">
    <Channel :channel="channel" />
  </div>
</template>

<script>
import CHANNELS from '@/gql/channels.gql'
import Channel from '@/components/Channel/Channel'
import { generateMetaTags, generateTitle } from '@/utils/head-generators'

export default {
  components: {
    Channel,
  },
  beforeRouteUpdate(to, from, next) {
    clearTimeout(this.timeoutIdView)
    clearTimeout(this.timeoutIdQualified)
    next()
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.timeoutIdView)
    clearTimeout(this.timeoutIdQualified)
    next()
  },
  async asyncData({ app, route, error }) {
    let channel = null

    try {
      const client = app.apolloProvider.defaultClient
      const slug = route.params.slug

      // channelSlugs route param is a string when resolving - create array manually
      const channelSlugs =
        typeof route.params.channelSlugs === 'string'
          ? route.params.channelSlugs.split('/')
          : [].concat(route.params.channelSlugs)

      const { data } = await client.query({
        query: CHANNELS,
        variables: {
          portalId: app.context.$config.portalId,
          filter_slug: [...channelSlugs, slug].filter((c) => !!c).join('/'),
        },
      })

      if (data.getChannelsByPortalId === null) {
        return error({ statusCode: 404 })
      }

      channel = data.getChannelsByPortalId.data[0]
    } catch (e) {
      return error({ statusCode: 500 })
    }

    return {
      channel,
    }
  },
  data: () => ({
    channel: null,
  }),
  head() {
    return {
      title: generateTitle(this.channel.attributes.pageTitle ? this.channel.attributes.pageTitle : '', this.$config),
      meta: generateMetaTags({
        title: this.channel.attributes.pageTitle ? this.channel.attributes.pageTitle : '',
        description: this.channel.attributes.description ? this.channel.attributes.description : '',
        url: this.$route.path,
        image: '/og-default.jpg',
        imageWidth: 100,
        imageHeight: 100,
        $config: this.$config,
      }),
    }
  },
  mounted() {
    this.timeoutIdView = setTimeout(() => {
      $nuxtLogger.info('Delayed for 10 second.', this.timeoutIdView)
      this.$fb.query('trackCustom', 'view-content')
    }, '10000')
    this.timeoutIdQualified = setTimeout(() => {
      $nuxtLogger.info('Delayed for 30 second.', this.timeoutIdQualified)
      this.$fb.query('trackCustom', 'qualified-user')
    }, '30000')
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.page-channel {
  max-width: $max-width-page;
  margin: auto;
}
</style>
